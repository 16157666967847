import { React, useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import "./style.css";
import "./Footer.css";
import "./Info_Section.css";
import "./Input_fields.css";
import "./Button.css";

import HeaderLower from "./HeaderLower";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

import StorageService from "../services/storage.service";
import { Helmet } from "react-helmet";

const UserformSurvey = (props) => {
  const [ans1, setAns1] = useState(" ");
  const [ans2, setAns2] = useState(" ");
  const [ans3, setAns3] = useState(" ");
  const [ans4, setAns4] = useState(" ");
  const [ans5, setAns5] = useState(" ");
  const [errors, setErrors] = useState({});
  const [captcha, setCaptcha] = useState(false);
  // const [signoutTime, setSignoutTime] = useState(180000);
  const [signoutTime, setSignoutTime] = useState(900000);

  const TITLE =
    process.env.REACT_APP_API_SITE_TITLE + " | Customer Survey Form";
  const navigate = useNavigate();

  // FIXME
  localStorage.setItem("returnpage", true);
  localStorage.setItem("page", "/survey-form");

  // StorageService.setReturnStatus(true);
  // StorageService.setPage("/UserFormSurvey");

  timeout();
  function timeout() {
    setTimeout(function () {
      logout();
    }, signoutTime);
  }

  const logout = () => {
    destroy();
  };

  const destroy = () => {
    StorageService.setReturnStatus(true);
    StorageService.destroyStorage();
  };

  const handleChangeans1 = (event) => {
    const ans1 = event.target.value;
    setAns1(ans1);
    localStorage.setItem("ans1", ans1);
    // StorageService.setAns("ans1", ans1);
  };
  const handleChangeans2 = (event) => {
    const ans2 = event.target.value;
    setAns2(ans2);
    localStorage.setItem("ans2", ans2);
  };
  const handleChangeans3 = (event) => {
    const ans3 = event.target.value;
    setAns3(ans3);
    localStorage.setItem("ans3", ans3);
  };
  const handleChangeans4 = (event) => {
    const ans4 = event.target.value;
    setAns4(ans4);
    localStorage.setItem("ans4", ans4);
  };
  const handleChangeans5 = (event) => {
    const ans5 = event.target.value;
    setAns5(ans5);
    localStorage.setItem("ans5", ans5);
  };

  function onCaptchaChange(value) {
    if (value === null) {
      setCaptcha(false);
    } else {
      setCaptcha(true);
    }
  }
  const submituserRegistrationForm = (e) => {
    e.preventDefault();
    if (validate()) {
      localStorage.setItem("userformopen", "ture");
      // StorageService.setFormStatus(true);
      if (captcha) {
        secureLocalStorage.setItem("Survey", {
          ans1: ans1,
          ans2: ans2,
          ans3: ans3,
          ans4: ans4,
          ans5: ans5,
        });
        navigate("/user-form");
      }
    } else {
      localStorage.setItem("userformopen", false);
      // StorageService.setFormStatus(false);
    }
  };

  const validate = () => {
    let errors = {};
    let formIsValid = true;

    if (ans1 === " ") {
      formIsValid = false;
      errors["ans1"] = "Please select the option";
      setErrors(errors);
      return false;
    }

    if (ans2 === " ") {
      formIsValid = false;
      errors["ans2"] = "Please select the option";
      setErrors(errors);
      return false;
    }
    if (ans3 === " ") {
      formIsValid = false;
      errors["ans3"] = "Please select the option";
      setErrors(errors);
      return false;
    }
    if (ans4 === " ") {
      formIsValid = false;
      errors["ans4"] = "Please select the option";
      setErrors(errors);
      return false;
    }
    if (ans5 === " ") {
      formIsValid = false;
      errors["ans5"] = "Please select the option";
      setErrors(errors);
      return false;
    }

    if (!captcha) {
      formIsValid = false;
      errors["checkederrorcaptcha"] = "Captcha not verified";
      setErrors(errors);
      return false;
    }
    return formIsValid;
  };

  const Voucher = localStorage.getItem("voucher");
  const userform = localStorage.getItem("userform");

  // const Voucher = StorageService.getVoucher();
  // const userform = StorageService.getUserformStatus();
  // if (userform === true) {
  //   return <Redirect to="/UserForm" />;
  // }
  useEffect(() => {
    if (userform === "true") {
      navigate("/user-form");
    }

    if (Voucher === undefined || Voucher == null) {
      localStorage.setItem("returnpage", false);
      localStorage.setItem("userform", false);
      localStorage.removeItem("page");
      localStorage.removeItem("ans1");
      localStorage.removeItem("ans2");
      localStorage.removeItem("ans3");
      localStorage.removeItem("ans4");
      localStorage.removeItem("ans5");
      navigate("/");
    }
    if (Voucher === undefined || Voucher == null) {
      StorageService.destroyVoucherInfo1();
      navigate("/");
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <div className="container-fluid">
        {/* <HeaderInner/> */}
        {/* <HeaderLower /> */}
        <div className="white-box form">
          <form
            name="userinfo"
            className="form-outer register clearfix"
            id="userinfo"
            onSubmit={submituserRegistrationForm}
            method="post"
            action=""
          >
            <div className="row">
              <h1 style={{ textAlign: "center" }}>
                {process.env.REACT_APP_SURVEYTITLE}
              </h1>
              <hr />
              <label>
                <b>1</b>. On a scale from 1 to 5, Please rate your overall satisfaction with the Rest test experience with the sale consultants: <span>*</span>
              </label>
              <div className="boxheading">
                <label className="heading1">
                  <b>Highly Dissatisfaction</b>
                </label>
                <label className="heading1 ml-5">
                  <b> Highly Satisfaction</b>
                </label>
              </div>
              <br />

              <label className="inline">
                <input
                  type="radio"
                  id="satisfaction_radio"
                  name="satisfaction_radio"
                  value="1"
                  onChange={handleChangeans1}
                />{" "}
                1
              </label>

              <label className="inline ml-2">
                <input
                  type="radio"
                  id="satisfaction_radio"
                  name="satisfaction_radio"
                  value="2"
                  onChange={handleChangeans1}
                />{" "}
                2
              </label>
              <label className="inline ml-2">
                <input
                  type="radio"
                  id="satisfaction_radio"
                  name="satisfaction_radio"
                  value="3"
                  onChange={handleChangeans1}
                />{" "}
                3
              </label>
              <label className="inline ml-2">
                <input
                  type="radio"
                  id="satisfaction_radio"
                  name="satisfaction_radio"
                  value="4"
                  onChange={handleChangeans1}
                />{" "}
                4
              </label>
              <label className="inline ml-2">
                <input
                  type="radio"
                  id="satisfaction_radio"
                  name="satisfaction_radio"
                  value="5"
                  onChange={handleChangeans1}
                />{" "}
                5
              </label>

              <label className="inline ml-2">
                <div className="errorMsg">{errors.ans1}</div>
                <span style={{ color: "red" }} id="satisfaction_msg"></span>
              </label>
              <hr />

              <label>
                <b>2</b>. Did the sales consultants demonstrate a power base during the rest test process?
                <span>*</span>
              </label>
              <label className="inline">
                <input
                  type="radio"
                  id="mattress_radio"
                  name="mattress_radio"
                  value="Yes"
                  onChange={handleChangeans2}
                />{" "}
                Yes
              </label>
              <label className="inline ml-2">
                <input
                  type="radio"
                  id="mattress_radio"
                  name="mattress_radio"
                  value="No"
                  onChange={handleChangeans2}
                />{" "}
                No
              </label>
              <label className="inline ml-2">
                <div className="errorMsg">{errors.ans2}</div>
                <span style={{ color: "red" }} id="mattress_msg"></span>
              </label>
              <hr />
              <label>
                <b>3</b>. Did you find your comfort level during the rest test process?<span>*</span>
              </label>
              <label className="inline">
                <input
                  type="radio"
                  id="experience_radio"
                  name="experience_radio"
                  value="Yes"
                  onChange={handleChangeans3}
                />{" "}
                Yes
              </label>
              <label className="inline ml-2">
                <input
                  type="radio"
                  id="experience_radio"
                  name="experience_radio"
                  value="No"
                  onChange={handleChangeans3}
                />{" "}
                No
              </label>

              <label className="inline ml-2">
                <div className="errorMsg">{errors.ans3}</div>
                <span style={{ color: "red" }} id="experience_msg"></span>
              </label>

              <hr />

              <label>
                <b>4</b>. Did we have all of the brands you were looking for?<span>*</span>
              </label>
              <label className="inline">
                <input
                  type="radio"
                  id="stomach_radio"
                  name="stomach_radio"
                  value="Yes"
                  onChange={handleChangeans4}
                />{" "}
                Yes
              </label>
              <label className="inline ml-2">
                <input
                  type="radio"
                  id="stomach_radio"
                  name="stomach_radio"
                  value="No"
                  onChange={handleChangeans4}
                />{" "}
                No
              </label>

              <label className="inline ml-2">
                <div className="errorMsg">{errors.ans4}</div>
                <span style={{ color: "red" }} id="stomach_msg"></span>
              </label>
              <hr />

              <label>
                <b>5</b>. On a scale from 1 to 5, Based on your experience how likely would you purchase your next mattress from Johnny Janosik?<span>*</span>
              </label>
              <div className="boxesheading">
                <label className="heading1">
                  <b>Not Likely</b>
                </label>
                <label className="heading1 ml-3">
                  <b>Likely</b>
                </label>
              </div>
              <br />
              <label className="inline">
                <input
                  type="radio"
                  id="store_radio"
                  name="store_radio"
                  value="1"
                  onChange={handleChangeans5}
                />{" "}
                1
              </label>
              <label className="inline ml-2">
                <input
                  type="radio"
                  id="store_radio"
                  name="store_radio"
                  value="2"
                  onChange={handleChangeans5}
                />{" "}
                2
              </label>
              <label className="inline ml-2">
                <input
                  type="radio"
                  id="store_radio"
                  name="store_radio"
                  value="3"
                  onChange={handleChangeans5}
                />{" "}
                3
              </label>
              <label className="inline ml-2">
                <input
                  type="radio"
                  id="store_radio"
                  name="store_radio"
                  value="4"
                  onChange={handleChangeans5}
                />{" "}
                4
              </label>
              <label className="inline ml-2">
                <input
                  type="radio"
                  id="store_radio"
                  name="store_radio"
                  value="5"
                  onChange={handleChangeans5}
                />{" "}
                5
              </label>

              <label className="inline ml-2">
                <div className="errorMsg">{errors.ans5}</div>
                <span style={{ color: "red" }} id="store_msg"></span>
              </label>

              <br />
              <br />
              <div className="overflow-hider">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_API_GOOGLESITEKEY}
                  onChange={onCaptchaChange}
                />
              </div>
              <div className="errorMsg">{errors.checkederrorcaptcha}</div>

              <span style={{ color: "red" }} id="response_msg"></span>
              <br />
            </div>
            <input
              // onClick={() => myTimeout()}
              type="submit"
              className="submit-btn survey"
              name="submit1"
              value="Submit and Move Next"
            />
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default UserformSurvey;
